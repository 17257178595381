 .td-action {
   width: 146px;

 }

 .remove-hyperlink {
   text-decoration: none;
 }

 .spacing-betweenn-td {
   padding-top: 2% !important;
   padding-bottom: 3% !important;
 }