@import '~@trv-tds/core/dist/css/tds.css';
.funky-class {
  color: #ff00ff;
}

.td-action {
  width: 146px;
}

.remove-hyperlink {
  text-decoration: none;
}

.spacing-betweenn-td {
  padding-top: 2% !important;
  padding-bottom: 3% !important;
}

@media print {
  #printDivConfirm.tds-offset-3 {
    margin-left: 5%;
  }
  #printDivAccess.tds-offset-3 {
    margin-left: 5%;
  }
  #printDivAccessBox.tds-offset-3 {
    margin-left: 5%;
  }
  #privacyandsecurity {
    display: none;
  }
  #termsofservice {
    display: none;
  }
}
@media print {
  .printMargin {
    margin-left: 5%;
  }
}
.cpa {
  height: 24px;
  width: 207px;
  color: #46494D;
  font-family: "Neue Haas Unica Pro";
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.27px;
  line-height: 24px;
}

.access {
  height: 24px;
  width: 79px;
  color: #42494F;
  font-family: "Neue Haas Unica Pro";
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.27px;
  line-height: 24px;
  margin-left: 85px;
}

.duplicate-access {
  font-family: BattersonSans, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #46494d;
  font-size: 0.75rem;
  letter-spacing: 0.09375rem;
  line-height: 1.6;
  text-transform: none !important;
  padding: 0;
  margin-left: 90px;
}

.date {
  height: 24px;
  width: 129px;
  color: #42494F;
  font-family: "Neue Haas Unica Pro";
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.27px;
  line-height: 24px;
}

.lining {
  padding-top: 21px;
}

.line-3-copy-14 {
  box-sizing: border-box;
  height: 1px;
  width: 480px;
  border: 1px solid #DADBDB;
}

/* To be removed when TDS adds the classes */
.hero {
  margin-bottom: 1.2rem;
  min-height: 12rem;
  text-align: center;
  width: 100%;
  background-color: #f6f6f6;
  padding: 2.4rem 1.2rem;
}

.hero h1 {
  margin-top: 0;
}

@media (min-width: 35.9375em) {
  .hero {
    padding: 3.6rem 1.2rem;
  }
  .hero a {
    width: auto;
  }
}
@media (min-width: 50em) {
  .hero {
    padding: 5.4rem 1.2rem;
  }
}
.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.tds-centered {
  text-align: center;
}

.rectangle-copy-delete {
  height: 66%;
  width: 100%;
  background-color: #f6f6f6;
}

.your-request-to-dele {
  color: #46494D;
  font-size: 42px;
  font-weight: 300;
  letter-spacing: 0.2px;
  line-height: 46px;
}

@media print {
  #reactangle-print {
    height: 176px;
    width: 619px;
  }
}
.rectangle-copy {
  height: 132px;
  width: 538px;
  background-color: #F6F6F6;
}

.mytrav {
  height: 60px;
  width: 461px;
}

.appHeader {
  border-bottom: 3px solid #cc2d30;
  transition: all 0.3s ease-in;
  left: 0px;
  background: #eee;
}

.header {
  height: 72px;
  width: 1500px;
  color: #46494D;
  font-size: 48px;
  font-weight: 200;
  letter-spacing: 0.23px;
  line-height: 54px;
}

.rectangle-copy-access-delete {
  height: 50%;
  width: 95%;
  background-color: #f6f6f6;
}

.rectangle-copy-access {
  height: 55%;
  width: 95%;
  background-color: #f6f6f6;
}

.type-delete-access {
  height: 24px;
  width: 143px;
  color: #42494F;
  font-family: "Neue Haas Unica Pro";
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.27px;
  line-height: 24px;
}

.line-4-copy-14 {
  box-sizing: border-box;
  height: 2px;
  width: 190%;
  border: 1px solid #DADBDB;
}

.header-access-delete {
  height: 92px;
  width: 654px;
  color: #46494D;
  font-size: 42px;
  font-weight: 300;
  letter-spacing: 0.2px;
  line-height: 46px;
}

@media print {
  #reactangle-print-access-delete {
    height: 254px;
    width: 619px;
    background-color: #f6f6f6;
  }
}
.rectangle-copy-access-delete {
  height: 55%;
  width: 95%;
  background-color: #f6f6f6;
}

.type-delete-access {
  height: 24px;
  width: 143px;
  color: #42494F;
  font-family: "Neue Haas Unica Pro";
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.27px;
  line-height: 24px;
}

.delete {
  color: #42494F;
  margin-top: -3%;
}

.line-3-copy-14 {
  box-sizing: border-box;
  height: 1%;
  width: 190%;
  border: 1px solid #DADBDB;
}

.rectangle-copy-access {
  height: 178px;
  width: 619px;
  background-color: #F6F6F6;
}

.your-request-to-acce {
  color: #46494D;
  font-family: "BattersonSans Pro";
  font-size: 40px;
  font-weight: 300;
  letter-spacing: 0.2px;
  line-height: 46px;
  margin-top: 10%;
}

.rectangle {
  height: 34px;
  width: 461px;
  background-color: #FFFFFF;
}

.for-your-reference {
  margin-left: 5%;
}

.track {
  margin-top: 5%;
}

.a-access-delete {
  text-Decoration: none;
  text-Transform: "none";
  height: 24px;
  width: 1228px;
  font-size: 15px;
  font-family: "BattersonSans Pro";
}

.header1 {
  height: 162px;
  width: 535px;
  color: #46494D;
  font-size: 48px;
  font-weight: 300;
  letter-spacing: 0.23px;
  line-height: 54px;
}

.header2 {
  width: 535px;
  color: #46494D;
  font-size: 38px;
  font-weight: 300;
  letter-spacing: 0.23px;
  line-height: 54px;
}

.p {
  height: 351px;
  width: 464px;
  color: #46494D;
  font-family: "Neue Haas Unica Pro";
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 27px;
}

.styles_modal__gNwvD {
  max-width: 640px !important;
}

.modal-text {
  color: #46494D;
  font-family: "Neue Haas Unica Pro Regular";
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 27px;
  text-align: left;
}

.tds-field__label-date-time-input {
  font-family: BattersonSans, sans-serif;
  font-weight: bold;
  font-style: normal;
  color: #272A2D;
  font-size: 0.75rem;
  letter-spacing: 0.09375rem;
  margin: 0;
  padding-bottom: 1px;
  padding-top: 7px;
}

.line-3-copy-5 {
  box-sizing: border-box;
  height: 1px;
  width: 620px;
  border: 1px solid #DADBDB;
}

.modal-header {
  color: #65686D;
  font-family: "BattersonSans Pro Medium";
  font-size: 26px;
  letter-spacing: 0.11px;
  line-height: 31px;
  text-align: center;
}

.styles_overlay__CLSq- {
  overflow-y: scroll !important;
  opacity: 1.5;
}

.tds-field__input-SSN {
  font-family: BattersonSans, sans-serif;
  font-weight: 400;
  font-style: normal;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0.375rem 0.1875rem 0.375rem;
  margin-bottom: -0.1875rem;
  text-overflow: ellipsis;
  width: 173px;
}

.rectangle-copy-duplicate {
  height: 178px;
  width: 619px;
  background-color: #F6F6F6;
}

.type-tds-large {
  font-family: BattersonSans, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.45rem;
  line-height: 1.5;
  margin-left: 77px;
  margin-top: 7px;
}

.h4-type {
  margin-left: 45%;
}

.tds-dropdown__label,
.tds-field__label {
  font-family: BattersonSans, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #46494d;
  font-size: 0.75rem;
  letter-spacing: 0.09375rem;
  line-height: 1.6;
  text-transform: none !important;
  padding: 0;
  margin: 0;
}

.here-is-your-most-re {
  height: 36px;
  width: 415px;
  color: #46494D;
  font-family: "Neue Haas Unica Pro";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 36px;
}

.duplicate-page-header {
  color: #46494D;
  font-size: 42px;
  font-weight: 300;
  letter-spacing: 0.2px;
  line-height: 46px;
  margin-top: 7%;
}

.tracking-number {
  height: 25px;
  width: 129px;
  color: #46494D;
  font-family: "Neue Haas Unica Pro";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.93px;
  line-height: 24px;
}

.type {
  height: 25px;
  width: 129px;
  color: #46494D;
  font-family: "Neue Haas Unica Pro";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.93px;
  line-height: 24px;
}

.date-submitted {
  height: 25px;
  width: 129px;
  color: #46494D;
  font-family: "Neue Haas Unica Pro";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.93px;
  line-height: 24px;
}

.rectangle-copy-2 {
  height: 34px;
  width: 461px;
  background-color: #FFFFFF;
}

.rectangle {
  height: 132px;
  width: 538px;
  background-color: #F6F6F6;
}

.input-state-z-elements-form-dr {
  height: 60px;
  width: 340px;
}

.field-label {
  height: 18.3px;
  width: 257px;
  color: #46494D;
  font-family: "BattersonSans Pro";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 18px;
}

.tds-field-SSN {
  font-family: BattersonSans, sans-serif;
  font-weight: 400;
  font-style: normal;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  font-size: 1.125rem;
  line-height: 1.5;
  padding: 0.375rem 0.1875rem 0.375rem;
  margin-bottom: -0.1875rem;
  text-overflow: ellipsis;
  width: 84px;
}

.field__label {
  font-family: BattersonSans, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #46494d;
  font-size: 0.75rem;
  letter-spacing: 0.09375rem;
  line-height: 1.6;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.tds-data-table tbody:last-of-type tr:last-of-type > * {
  padding-bottom: 43px;
}

input[type=text]::-ms-clear {
  display: none;
}

input[type=email]::-ms-clear {
  display: none;
}

.tds-alert-warning {
  height: 30px;
  color: #d3ab00 !important;
  fill: #d3ab00 !important;
}

.question-copy-2 {
  height: 31px;
  width: 500px;
  color: #5A5D62;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.11px;
  line-height: 31px;
  text-align: center;
}

.question-copy-3 {
  height: 58px;
  width: 500px;
  color: #505357;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.1px;
  line-height: 29px;
  text-align: center;
}

.a-disclosure {
  height: 54px;
  width: 335px;
  color: #46494D;
  font-family: "Neue Haas Unica Pro";
  font-size: 17px;
  letter-spacing: 0.1px;
  line-height: 18px;
}

@media print {
  #react-no-print {
    display: none;
  }
}
.align-center {
  text-align: center;
}

.margin-top-xx-large {
  margin-top: 48px;
}

.h1-size {
  font-size: 36px;
  font-weight: 600;
  margin-top: -15px;
}

.tds-li,
li {
  margin-bottom: 0.2rem;
}

.font-famly {
  font-family: Neue Haas Unica Pro;
}

.click-here {
  text-align: center;
  text-decoration: none !important;
  text-transform: none;
  color: #00526B;
  font-size: 24px;
  font-family: BattersonSans Pro Semibold;
  letter-spacing: 0.2px;
}

.click-text {
  font-size: 24px;
  font-family: BattersonSans Pro Semibold;
  letter-spacing: 0.2px;
}

.acxiom-ques-spacing {
  margin-top: 30px;
}

.acxiom-heading {
  color: #46494D;
  font-size: 42px;
  height: 46px;
  line-height: 46px;
}

.margin-acxiom {
  margin-left: 33.3%;
}

.text-decor {
  text-decoration: none;
}

.view-container-medium {
  width: 50%;
  margin: auto;
}

hr.content-divider {
  height: 1px;
  border-width: 0;
  color: #dadbdb;
  background-color: #dadbdb;
}

.tds-field-position {
  margin-bottom: 45px;
  position: relative;
  margin-top: 45px;
}

.tds-margin-bottom {
  margin-bottom: 5%;
}

.ml25 {
  margin-left: 25%;
}

.ml30 {
  margin-left: 30%;
}

.ml33 {
  margin-left: 33%;
}

.mt20 {
  margin-top: 20px;
}

.tds-field__message {
  padding-bottom: 0px;
}

.tds-dropdown__label,
.tds-field__label {
  font-weight: bold;
  color: #272A2D;
}

.width100 {
  width: 100%;
}

.tds-field--error {
  color: #874600 !important;
}

.tds-check--info input:not(:disabled):not([aria-disabled=true]) + .tds-check__label {
  color: #272A2D;
}

.tds-check--info input:not(:disabled):not([aria-disabled=true]) + .tds-check__label::before {
  border-color: #272A2D;
}

.tds-button--primary {
  white-space: normal !important;
}

.formbg {
  background-color: #F6F6F6;
  outline: 18px solid #F6F6F6;
}

.tds-tooltip__container .tds-tooltip,
.tds-tooltip__container [role=tooltip] {
  background-color: #0068a9;
  color: #fff;
  border-color: #0068a9;
  border-radius: 0.1875rem;
  padding: 0.375rem 0.5625rem;
  opacity: 0;
  display: block;
  min-width: 3.125rem;
  transition: opacity 0.1s linear, transform 0.1s linear, z-index 0s linear 0.5s, -webkit-transform 0.1s linear;
  text-transform: none;
  position: absolute;
  visibility: hidden;
  top: auto;
  right: auto;
  bottom: calc(100% + 0.5625rem);
  left: 50%;
  transform: translate(-50%, 0.625rem);
  z-index: -1;
}

.tds-tooltip {
  font-size: 11px !important;
  text-align: left !important;
  width: 169px !important;
  max-width: 169px !important;
  white-space: normal !important;
}

.textDecNone {
  text-decoration: none;
}

.tds-requestConfirmationPage {
  padding: 0.75rem;
  background-color: #f6f6f6;
}

.blueColor {
  color: #007AA9;
}

@media only screen and (max-width: 760px) {
  .tds-container {
    padding: 0rem 0.5rem;
  }
}
.tds-check--large label {
  max-width: none;
}

.tds-data-table-scrollpanel {
  border-bottom: none;
}

.tds-data-table {
  border-bottom: 1px solid rgb(107, 109, 113);
}